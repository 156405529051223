import { inject } from '@angular/core';
import { type ActivatedRouteSnapshot, type ResolveFn } from '@angular/router';
import {
  type AvailableLang,
  LanguageConfigToken,
} from '@cca-common/translations';
import { LoggedInSignalToken } from '@cca-infra/core';
import { TranslocoService } from '@jsverse/transloco';

export const publicLanguageResolver: ResolveFn<unknown> = (
  route: ActivatedRouteSnapshot,
) => {
  const loggedIn = inject(LoggedInSignalToken)();
  if (!loggedIn) {
    const transloco = inject(TranslocoService);
    const langConfig = inject(LanguageConfigToken);
    const wantedLang = route?.queryParamMap?.get('lang');
    if (wantedLang) {
      // find available lang
      const availableLang = langConfig.availableLangs.find(
        (x: AvailableLang) => x.id == wantedLang,
      );

      // set the lang
      if (availableLang) {
        transloco.setActiveLang(availableLang?.id);
      }
    }
  }
  return true;
};
