import { ApplicationRef, enableProdMode } from '@angular/core';
import {
  enableDebugTools,
  bootstrapApplication,
} from '@angular/platform-browser';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app-config';
import { enableDevMenu } from '@cca-common/feature-dev';

// declaring some stuff upfront needing to configure these
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    smartlook: any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clarity: any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    HubSpotConversations: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hsConversationsSettings: any;

    enableDevMenu: () => void;
  }
}

// smartlook stuff
// eslint-disable-next-line @typescript-eslint/no-empty-function
window.smartlook = window?.smartlook || (() => {});

try {
  if (environment.smartLookKey && window?.smartlook) {
    window?.smartlook('init', environment?.smartLookKey, {
      region: 'eu',
    });
  }
} catch {
  /* empty */
}

if (environment.production) {
  enableProdMode();
}

window.enableDevMenu = enableDevMenu;

bootstrapApplication(AppComponent, appConfig)
  .then((moduleRef) => {
    if (!environment.production) {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      // allows to run `ng.profiler.timeChangeDetection();`
      enableDebugTools(componentRef);
    }
  })
  .catch((err) => console.error(err));
