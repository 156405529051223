import { type NavigationLinkType } from '../navigation';
import { environment } from '../../../environments/environment';
import { navigationPermissionCheck } from './permission-check';

export const accessGuardFactory = (
  navigation: NavigationLinkType | undefined,
) => {
  if (!navigation) {
    if (!environment.production) {
      throw Error(
        `accessGuardFactory is missing a navigationLink, you might have missed it or you search query did not find one`,
      );
    }
    return () => false;
  }

  return () => navigationPermissionCheck(navigation);
};
