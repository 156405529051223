import { inject, isDevMode, NgModule } from '@angular/core';
import { EventType, Router, RouterModule, type Routes } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { authGuardFn, unAuthGuardFn } from '@cca-common/authentication';
import { HoverPreloadStrategy } from '@cca-common/cdk';
import { accessGuardFactory, indexGuardFn } from './core/guards';
import { flatMenuItems } from './core/navigation';
import { NoopComponent } from '@cca/ui';
import { environment } from '../environments/environment';
import { publicLanguageResolver } from './public-language-resolver';
import { filter } from 'rxjs';

const routes: Routes = [
  {
    path: '',
    resolve: {
      // resolver will set the language if its public, we don't actually need this information in any of the routes
      lang: publicLanguageResolver,
    },
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('@cca-authentication/feature-login').then((m) => m.routes),
        canActivate: [unAuthGuardFn],
      },
      {
        path: 'activation',
        loadChildren: () =>
          import('@cca-authentication/feature-activation').then(
            (m) => m.routes,
          ),
        canActivate: [unAuthGuardFn],
      },
      {
        path: 'tracktrace',
        loadChildren: () =>
          import('@cca-order/feature-tracking').then((m) => m.routes),
      },
      {
        path: 'public',
        children: [
          {
            path: 'tender',
            loadChildren: () =>
              import('@cca-tender/feature-public').then((m) => m.routes),
          },
          {
            path: 'timeslots',
            loadChildren: () =>
              import('@cca-order/feature-public-timeslot').then(
                (m) => m.OrderFeaturePublicTimeslotModule,
              ),
          },
          {
            path: 'carrierAcceptOrder',
            loadChildren: () =>
              import('@cca-order/feature-public-carrier-accept').then(
                (m) => m.OrderFeaturePublicCarrierAcceptModule,
              ),
          },
        ],
      },
      {
        path: '',
        loadComponent: () =>
          import('./core/main/main.component').then((m) => m.MainComponent),
        children: [
          {
            path: 'page-not-found',
            loadComponent: () =>
              import('./pages').then((m) => m.PageNotFoundComponent),
          },
          {
            path: 'page-not-accessible',
            loadComponent: () =>
              import('./pages').then((m) => m.PageNotAccessibleComponent),
          },
          {
            path: 'page-not-permitted',
            loadComponent: () =>
              import('./pages').then((m) => m.PageNotPermittedComponent),
          },
          {
            path: 'dashboard',
            loadChildren: () =>
              import('@cca-dashboard/feature-dashboard').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/dashboard'),
              ),
            ],
          },
          {
            path: 'home',
            loadChildren: () =>
              import('@cca-dashboard/feature-home').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/home'),
              ),
            ],
          },
          {
            path: 'dashboard-kpis',
            loadChildren: () =>
              import('@cca-dashboard/feature-kpis').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/dashboard-kpis'),
              ),
            ],
          },
          {
            path: 'taskboard',
            loadChildren: () =>
              import('@cca-dashboard/feature-taskboard').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/taskboard'),
              ),
            ],
          },
          {
            path: 'booking',
            loadChildren: () =>
              import('@cca-booking/feature-shipper').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/booking'),
              ),
            ],
          },
          {
            path: 'order',
            loadChildren: () =>
              import('@cca-order/feature-shipper-management').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/order'),
              ),
            ],
          },
          {
            path: 'request',
            loadChildren: () =>
              import('@cca-request/feature-general').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/request'),
              ),
            ],
          },
          {
            path: 'tender',
            loadChildren: () =>
              import('@cca-tender/feature-general').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/tender'),
              ),
            ],
          },
          //CTMS stuff, coming --> SOON <-- (maybe)
          // {
          //   path: 'create-orders',
          //   loadChildren: () =>
          //     import('@cca-booking/feature-carrier-booking').then(
          //       (m) => m.routes,
          //     ),
          //   canActivate: [
          //     accessGuardFactory(
          //       flatMenuItems.find((item) => item.link === '/create-orders'),
          //     ),
          //   ],
          // },
          // {
          //   path: 'carrier-orders',
          //   loadChildren: () =>
          //     import('@cca-order/feature-carrier-management').then(
          //       (m) => m.routes,
          //     ),
          //   canActivate: [
          //     accessGuardFactory(
          //       flatMenuItems.find((item) => item.link === '/carrier-orders'),
          //     ),
          //   ],
          // },
          // {
          //   path: 'customer-groups',
          //   loadChildren: () =>
          //     import('@cca-user/feature-customer-groups').then((m) => m.routes),
          //   canActivate: [
          //     accessGuardFactory(
          //       flatMenuItems.find((item) => item.link === '/customer-groups'),
          //     ),
          //   ],
          // },
          // {
          //   path: 'customer-lane',
          //   loadChildren: () =>
          //     import('@cca-lane/feature-customer-management').then(
          //       (m) => m.routes,
          //     ),
          //   canActivate: [
          //     accessGuardFactory(
          //       flatMenuItems.find((item) => item.link === '/customer-lane'),
          //     ),
          //   ],
          // },
          // {
          //   path: 'customer-rates',
          //   loadChildren: () =>
          //     import('@cca-finance/feature-customer-rate-management').then(
          //       (m) => m.routes,
          //     ),
          //   canActivate: [
          //     accessGuardFactory(
          //       flatMenuItems.find((item) => item.link === '/customer-rates'),
          //     ),
          //   ],
          // },
          // {
          //   path: 'customer-routes',
          //   loadChildren: () =>
          //     import('@cca-fleet/feature-customer-routes').then(
          //       (m) => m.routes,
          //     ),
          //   canActivate: [
          //     accessGuardFactory(
          //       flatMenuItems.find((item) => item.link === '/customer-routes'),
          //     ),
          //   ],
          // },
          {
            path: 'trips',
            loadChildren: () =>
              import('@cca-order/feature-trips').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/trips'),
              ),
            ],
          },
          {
            path: 'trip-planner',
            loadChildren: () =>
              import('@cca-order/feature-trip-planner').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/trip-planner'),
              ),
            ],
          },
          {
            path: 'fleet',
            loadChildren: () =>
              import('@cca-fleet/feature-management').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/fleet'),
              ),
            ],
          },
          {
            path: 'tracking-invites',
            loadChildren: () =>
              import('@cca-fleet/feature-co3-management').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/tracking-invites'),
              ),
            ],
          },
          {
            path: 'carriercorridors',
            loadChildren: () =>
              import('@cca-fleet/feature-carrier-corridor-management').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/carriercorridors'),
              ),
            ],
          },
          {
            path: 'tracking',
            loadChildren: () =>
              import('@cca-fleet/feature-tracking').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/tracking'),
              ),
            ],
          },
          {
            path: 'analysis',
            loadChildren: () =>
              import('@cca-fleet/feature-analysis').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/analysis'),
              ),
            ],
          },
          {
            path: 'lane',
            loadChildren: () =>
              import('@cca-lane/feature-management').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/lane'),
              ),
            ],
          },
          {
            path: 'addressbook',
            loadChildren: () =>
              import('@cca-location/feature-addressbook-management').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/addressbook'),
              ),
            ],
          },
          {
            path: 'user',
            loadChildren: () =>
              import('@cca-user/feature-user-management').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/user'),
              ),
            ],
          },
          {
            path: 'groups',
            loadChildren: () =>
              import('@cca-user/feature-group-management').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/groups'),
              ),
            ],
          },
          {
            path: 'rates',
            loadChildren: () =>
              import('@cca-finance/feature-group-rate-management').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/rates'),
              ),
            ],
          },
          {
            path: 'invoicing',
            loadChildren: () =>
              import('@cca-finance/feature-invoicing-management').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/invoicing'),
              ),
            ],
          },
          {
            path: 'translation',
            loadChildren: () =>
              import('@cca-admin/feature-translation-management').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/translation'),
              ),
            ],
          },
          {
            path: 'glossary',
            loadChildren: () =>
              import('@cca-admin/feature-glossary-management').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/glossary'),
              ),
            ],
          },
          {
            path: 'terms',
            loadChildren: () =>
              import('@cca-admin/feature-terms-management').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/terms'),
              ),
            ],
          },
          {
            path: 'release-notes',
            loadChildren: () =>
              import('@cca-admin/feature-release-notes').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/release-notes'),
              ),
            ],
          },
          {
            path: 'user-projection',
            loadChildren: () =>
              import('@cca-admin/feature-user-projection').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/user-projection'),
              ),
            ],
          },
          {
            path: 'user-profile',
            loadChildren: () =>
              import('@cca-user/feature-user-profile').then((m) => m.routes),
          },
          {
            path: 'system-settings',
            loadChildren: () =>
              import('@cca-admin/feature-system-settings').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/system-settings'),
              ),
            ],
          },
          {
            path: 'logging',
            loadChildren: () =>
              import('@cca-admin/feature-logging').then((m) => m.routes),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/logging'),
              ),
            ],
          },
          {
            path: 'accounting-connection/:groupId',
            loadChildren: () =>
              import('@cca-finance/feature-accounting-connection').then(
                (m) => m.routes,
              ),
            canActivate: [
              accessGuardFactory(
                flatMenuItems.find((item) => item.link === '/invoicing'),
              ),
            ],
          },
          {
            path: '**',
            canActivate: [indexGuardFn],
            component: NoopComponent,
          },
        ],
        // whenever a route under the main route activates we run this function
        canActivateChild: [authGuardFn],
      },
      {
        path: 'clear-site-data',
        redirectTo: '/',
      },
    ],
  },
];

const webpackModuleFailedMessage = /Loading chunk [\d]+ failed/;
const esbuildModuleFailedMessage =
  /Failed to fetch dynamically imported module/;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: HoverPreloadStrategy,
      initialNavigation: 'enabledNonBlocking',
      // enableTracing: true,
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always',
      resolveNavigationPromiseOnError: true,
      enableViewTransitions: true,
    }),
  ],
  providers: [HoverPreloadStrategy],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {
    const router = inject(Router);
    const swUpdate = inject(SwUpdate);
    router.events
      .pipe(filter((event) => event.type === EventType.NavigationError))
      .subscribe((event) => {
        console.log(event);
        const error = event.error;
        const loadingFailed =
          webpackModuleFailedMessage.test(error?.message) ||
          esbuildModuleFailedMessage.test(error?.message);
        if (environment.production && loadingFailed) {
          swUpdate.checkForUpdate();
        } else {
          // for logging the error when in devMode
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          isDevMode() && console.error(error);
        }
      });
  }
}
